import React from "react";

interface Props { }
export const Terms: React.FC<Props> = () => {
    return (
        <>
            <h1 className="c8" id="h.41q0z9cryso6"><span className="c10">User Terms</span></h1>
            <p className="c5"><span className="c4">Last modified: 29 June 2020</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span>The terms of this agreement ("Terms of Use") govern the relationship between you and iDream
                Interactive, Inc., with its address at 205-1501 Howard Ave., Windsor, Ontario, Canada N8X 3T5 ("iDream" or "Us"
                or "We") regarding your use of iDream's games, websites and related services (the "Service"), including all
                information, text, graphics, software, and services, available for your use. By using this website and the
                services offered on it, downloading any software, or browsing the website, as well as by downloading any of
            </span><span>iDream’s</span><span className="c4">&nbsp;mobile games from app stores, you accept the following Terms of
                Use. If you do NOT agree to all these Terms of Use, please do NOT use this website and/or download any of our
                games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">iDream is authorized to amend the Terms of Use at any time, with the amended Terms of Use
                effective as soon as they are posted on this website. Please check the most current Terms of Use to ensure that
                you are aware of all the terms and conditions regulating your use of this website and iDream’s games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">iDream reserves the right to make changes to or update the content of the website and its
                games or the format thereof at any time and without any notice. iDream reserves the right to terminate or
                restrict access to the website for any reason whatsoever at its sole discretion.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.ngb2cmj1clls"><span className="c0">WARRANTIES AND DISCLAIMERS</span></h2>
            <p className="c5"><span className="c4">ALL INFORMATION, SOFTWARE, AND SERVICES OFFERED ON THIS WEBSITE ARE PROVIDED "AS IS."
                IDREAM HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED
                TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">IDREAM MAKES NO WARRANTY, REPRESENTATION, OR GUARANTY AS TO THE CONTENT, SEQUENCE,
                ACCURACY, TIMELINESS, OR COMPLETENESS OF ITS INFORMATION, SOFTWARE, OR SERVICES. IDREAM MAKES NO WARRANTY,
                REPRESENTATION, OR GUARANTY THAT THE INFORMATION, PRODUCTS, OR SERVICES WILL BE UNINTERRUPTED OR ERROR FREE OR
                THAT ANY DEFECTS WILL BE CORRECTED.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">IDREAM ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN THE INFORMATION, SOFTWARE, OR
                SERVICES REFERENCED OR LINKED TO ON THIS WEBSITE.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">IN NO CASE SHALL IDREAM BE HELD LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE,
                SPECIAL, OR INCIDENTAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, THOSE RESULTING FROM A LOSS OF BUSINESS, DATA, OR
                REVENUE; RELIANCE ON THE MATERIALS PRESENTED; DELAYS; OR BUSINESS INTERRUPTIONS ARISING OUT OF OR IN CONNECTION
                WITH THE USE OR PERFORMANCE OF IDREAM INFORMATION) REGARDLESS OF WHETHER IDREAM HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">DOWNLOADING AND USING ANY OF THE SOFTWARE OR SERVICES OFFERED ON THE WEBSITE IS DONE AT
                YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ALL DAMAGE TO YOUR DEVICE SYSTEM, LOSS OF DATA,
                OR OTHER HARM THAT RESULTS FROM SUCH ACTIVITIES.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.6q1amakzr1up"><span className="c0">Copyright</span></h2>
            <p className="c5"><span className="c4">Copyrights, trademarks, and all other proprietary rights shown in the content (including,
                but not limited to, software, services, text, graphics, and logos) are reserved to iDream and protected by
                international copyright laws. You agree not to copy, republish, modify, download, distribute, license,
                sublicense, reverse engineer, or create derivatives based on the site, its software, or its services except as
                expressly authorized herein. Except as otherwise provided, the content published on this website and/or in the
                app stores may be reproduced or distributed in unmodified form for personal, non-commercial use only. Any other
                use of the content, including, without limitation, distribution, reproduction, modification, display, or
                transmission, without the prior written consent of iDream is strictly prohibited. All copyright and other
                proprietary notices shall be retained on all reproductions.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">iDream disclaims all proprietary interests in its intellectual property rights other than
                its own. References to third-party services and software are given by iDream "AS IS," without warranty of any
                kind, either expressed or implied.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.9htgwy9735mz"><span className="c0">Online Purchasing</span></h2>
            <p className="c5"><span className="c4">When conducting online purchases with iDream.com, you must provide true, accurate, and
                complete information about yourself and provide a non-fraudulent means of payment. If you provide any personal
                or financial information that is untrue, inaccurate, or incomplete, or if iDream has reasonable grounds to
                suspect that the information you provide is untrue, inaccurate, or incomplete, iDream has the right to void
                related financial transactions, to revoke all associated software licenses acquired via such transactions, and
                to refer to appropriate authorities the details of such incidents.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.vc6mlea33cz4"><span className="c0">In-Game Currencies and Goods</span></h2>
            <p className="c5"><span className="c4">iDream’s mobile and social games include virtual in-game currency (“Virtual Currency”),
                such as coins, cash, and/or diamonds, that can be purchased for real money and in turn used to purchase virtual
                in-game items (“Virtual Items”). Please note that iDream does not handle and is not responsible for handling
                transactions acquiring Virtual Currency. All Virtual Currency transactions are handled by third-party e-commerce
                payment providers. By purchasing in-game Virtual Currency, you are bound by and agree to the third-party payment
                providers’ Terms of Use.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">In addition to all third-party obligations, the following shall apply. In no way can
                Virtual Currency or Virtual Items be exchanged with us or anyone else for real money, goods, other items, or
                services of monetary value. Transferring Virtual Currency or Virtual Items outside the Games is strictly
                prohibited, meaning that you may not buy or sell Virtual Currency or Virtual Items for real money or otherwise
                exchange Virtual Currency or Virtual Items for items of value outside the Games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You also agree that all prices and sales of Virtual Currency and Virtual Items are final.
                Neither third-party payment providers nor iDream issue refunds for completed transactions except as provided in
                this Terms of Use. Please note that you only purchase a limited, revocable, non-transferable license to use
                in-game Virtual Currency or Virtual Items, and you agree that you do not own them. In the event your account is
                limited, terminated, suspended, modified, or deleted for any reason, at iDream’s sole and absolute discretion,
                or if iDream discontinues its Games, you forfeit any and all Virtual Currency and Virtual Items earned or
                purchased. iDream has the absolute right to manage, regulate, control, modify, and/or eliminate Virtual Currency
                and/or Virtual Items as it sees fit and at its sole discretion, and iDream is under no obligation to compensate
                you or anyone else for any resulting losses.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.epoermzasht0"><span className="c0">Refunds</span></h2>
            <p className="c5"><span className="c4">If you give your account or payment details to someone else, appear to be abusing our
                policies, or don’t protect your account with authentication, we usually can’t issue a refund.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">In case of refund, payment processor's standard terms and conditions regarding refunds
                will apply. User refunds are exclusive of taxes previously charged t o users &nbsp;for product purchases.</span>
            </p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.fet3aybj91pz"><span className="c0">Special refund regulation for Google Play</span></h2>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">If the purchase isn’t what you expected, doesn’t work, or you don’t want it any more, you
                can:</span></p>
            <p className="c3"><span className="c4"></span></p>
            <ol className="c7 lst-kix_9vh27uf6tbn5-0 start" start={1}>
                <li className="c2"><span className="c4">Request a refund on the Google Play website. If it’s less than 48 hours since you
                    bought an app or made an in-app purchase, you can request a refund through Google Play, or</span></li>
                <li className="c2"><span className="c4">Get support from iDream.</span></li>
            </ol>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You should contact iDream if:</span></p>
            <p className="c3"><span className="c4"></span></p>
            <ul className="c7 lst-kix_firqdggzqbjw-0 start">
                <li className="c2"><span className="c4">You have a question about the Games.</span></li>
                <li className="c2"><span className="c4">You made an in-game purchase but it wasn’t delivered or isn’t working as you
                    expected.</span></li>
                <li className="c2"><span className="c4">You want a refund and it’s more than 48 hours since you made the purchase. Please
                    note that according to our rules you are not entitled to refund if it’s more than 30 calendar days since you
                    made the purchase. Each request for refund are subject to iDream investigation. Refund will be given if
                    iDream finds the request acceptable according to this Terms of Use.</span></li>
            </ul>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">How to find our contact information (for Google Play)</span></p>
            <p className="c3"><span className="c4"></span></p>
            <ol className="c7 lst-kix_bahfszin25ol-0 start" start={1}>
                <li className="c2"><span>Visit </span><span className="c9"><a className="c6"
                    href="https://www.google.com/url?q=https://play.google.com/store&amp;sa=D&amp;ust=1594837131609000&amp;usg=AOvVaw3iLzsYBAlPnOnmTecwZls1">https://play.google.com/store</a></span><span
                        className="c4">.</span></li>
                <li className="c2"><span className="c4">Browse or search for the Game.</span></li>
                <li className="c2"><span className="c4">Locate and select the Game to open the detail page.</span></li>
                <li className="c2"><span className="c4">Scroll down to the "Additional information" section.</span></li>
                <li className="c2"><span className="c4">Review the contact information listed.</span></li>
            </ol>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">When you contact us, please, let us know:</span></p>
            <p className="c3"><span className="c4"></span></p>
            <ul className="c7 lst-kix_u1qqqd9xuu6f-0 start">
                <li className="c2"><span className="c4">The Game you’re using.</span></li>
                <li className="c2"><span className="c4">The issue you’re having. Such as "My in-app purchase didn’t come through," or "the
                    app isn’t working properly when I open it."</span></li>
                <li className="c2"><span className="c4">The response you’d like. For example, mention whether you’d like help fixing the
                    issue or would like a refund for your purchase.</span></li>
            </ul>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">If your inquiry is about a problem with a purchase you made, you should expect a response
                within three business days and within 24 hours to any support or product concerns stated to be urgent by
                Google.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.5scsx4flbhvo"><span className="c0">Special refund regulation for AppStore</span></h2>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You can report to Apple a problem with any purchase that you've made using the link
                http://reportaproblem.apple.com/. &nbsp;If technical problems prevent or unreasonably delay delivery the Game,
                your exclusive and sole remedy is either replacement of the Game or refund of the price paid, as determined by
                Apple. From time to time, Apple may refuse a refund request if Apple finds evidence of fraud, refund abuse, or
                other manipulative behavior. Please see https://support.apple.com/ &nbsp;for more information.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.7gkhas9klcay"><span className="c0">Rights to Content</span></h2>
            <p className="c5"><span>“Content” refers to all software (including without limitation all games, titles, and computer
                code), communications (including without limitation all stories, dialogues, concepts, documentation, and
                character profile information), images, sounds, audio-visual effects, accounts, Virtual Currency and Virtual
                Items, and material produced by iDream and/or received or made available while playing the Games or developed
                during the course of the Games. Content also includes all feedback, comments, or suggestions that iDream
                receives from you regarding the Games. Unless otherwise expressly stated in writing, you understand and agree
                that all Content is owned, controlled, and/or licensed by iDream. iDream reserves the right to use all Content
                for any purpose, including without limitation all commercial and/or promotional use, without restriction by or
                compensating you. All Content is copyrighted under the copyright laws of Republic of Ireland and/or similar laws
                of other jurisdictions, protecting it from unauthorized use. iDream reserves all rights, including, without
                limitation, all intellectual property rights and other proprietary rights to and relating to its Games. You are
                not permitted to copy, redistribute, publish, create any derivative work from, or otherwise exploit our Content
                or any part of our Content in violation of </span><span>iDream’s</span><span className="c4">&nbsp;and/or any other
                    third party’s intellectual property rights. iDream, its logos, Game titles, and all related characters and
                    elements are trademarks of iDream and may not be used without its prior written consent.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">If you are deemed to have retained any right to, title of, or interest in our Content or
                any part of our Content, you agree to and hereby assign solely and exclusively to iDream all of your rights to,
                titles of, and interests in such Content or any part of such Content without additional consideration and in
                perpetuity under all applicable laws. If, for any reason, such assignment is ineffective under applicable law,
                you hereby grant iDream the sole, exclusive, irrevocable, sublicensable, transferable, worldwide, royalty-free
                license to reproduce, modify, create any derivative work from, publish, distribute, sell, transfer, transmit,
                publicly display, use, and practice such Content or any part of such Content, and to incorporate the same in
                other works in any form, media, or technology now known or later developed. To the extent permitted by
                applicable laws, you hereby agree to waive all moral rights or rights of publicity or privacy you may have to
                such Content.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">At our discretion, iDream representatives or technology may monitor certain Content in
                the Games, though it cannot and makes no undertaking to monitor all Content. We may edit, refuse to post, or
                remove any Content posted in the Games or in any Games-related sources that is deemed objectionable or violates
                these Terms or the spirit of these Terms at our sole discretion and determination. If you encounter something
                you find objectionable and in violation of these Terms, you can bring it to our attention by contacting us. You
                understand and agree that we are not responsible or liable for any Content generated by users of the Games. This
                implies that users, not us, are entirely responsible for the completeness, accuracy, and/or usefulness of all
                the Content they upload, communicate, transmit, and/or otherwise make available via our Games and related
                services.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You acknowledge and agree that iDream may use built-in tracking features to obtain
                information regarding your use of the Games in order to improve the services we provide, and agree that such
                information is deemed to be Content for all intents and purposes under these Terms.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.y6qgee6x6yle"><span className="c0">Information Provided by You</span></h2>
            <p className="c5"><span className="c4">The use of all materials and information sent through or in connection with this website
                and/or iDream’s software by you is subject to our Privacy Policy.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.xptjxxvt9iq7"><span className="c0">Use of Software</span></h2>
            <p className="c5"><span className="c4">The software available for download on this website or in app stores, as well as
                available on social networks, and all its documentation are the copyrighted work of iDream. Use of that software
                is governed by the terms of the end-user license agreement that accompanies or is included in it. You are not
                able to use, download, or install any software without first agreeing to the terms of the end-user license
                agreement that accompanies or is included in it.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You may not use, copy, republish, download, modify, distribute, license, sublicense,
                decompile, disassemble, create a derivative work based on, or reverse engineer the software or other products,
                services, or processes accessible through our website or Games except as expressly authorized herein or in the
                applicable end-user license agreement. You also acknowledge and agree that you will not transmit, upload, or
                attempt to transmit or upload viruses, adware, spyware, worms, or any other malicious or invasive code.</span>
            </p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">The software is supplied "AS IS." iDream disclaims all warranties, expressed or implied,
                including, but not limited to, warranties of merchantability and fitness for any purpose with respect to the
                software. You assume the entire risk of using the software.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You understand that iDream’s introduction of various technologies may not be consistent
                across all platforms and that the performance and some features offered by us may vary depending on your device
                and other equipment.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">iDream grants you a non-exclusive, non-transferable, revocable, and limited license to
                use our software. You may not use iDream software for any purpose other than that described in the license
                granted to you. Any illegal use of our software is solely your responsibility.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.4fhbt4aih4t6"><span className="c0">User Conduct</span></h2>
            <p className="c5"><span className="c4">You may be required to register an account (the “Account”) on the Service. Your use of
                the Service is governed by and maintained and enforced by iDream through “User Conduct.” You are entirely
                responsible for knowing, understanding, and abiding by User Conduct. The User Conduct rules are not exhaustive,
                and iDream reserves the right to determine which conduct is considered to be outside the spirit of the Games and
                to take disciplinary measures, including the termination and deletion of user accounts, prohibiting user from
                using the Service in whole or in part. User Conduct governs all aspects of your interaction with the Games,
                including, without limitation, selecting an in-game name, posting messages, interacting with other users, and
                otherwise accessing the Games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You acknowledge and agree that if iDream finds, at its sole discretion, that you have
                violated User Conduct, you may no longer have access to the violating item/items and/or iDream reserves the
                right to take actions, which may include terminating your account and prohibiting you from using the Service in
                whole or in part.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">You agree that you will not:</span></p>
            <p className="c3"><span className="c4"></span></p>
            <ul className="c7 lst-kix_qm2c2byx293k-0 start">
                <li className="c2"><span className="c4">Transmit or post any content or use language deemed to be offensive at iDream’s sole
                    discretion, including, without limitation, content or language that is unlawful, harmful, threatening,
                    abusive, harassing, defamatory, vulgar, obscene, hateful, sexually explicit, or racially, ethnically, or
                    otherwise objectionable, nor may you misspell or use an alternative spelling to circumvent or attempt to
                    circumvent the content and language restrictions listed above</span></li>
                <li className="c2"><span className="c4">Post and/or make available to the public any other user’s personal information in
                    the Games and/or any game-related services without that user’s express written consent, though users may
                    communicate their own personal information</span></li>
                <li className="c2"><span className="c4">Violate the contractual, personal, intellectual property, or other rights of any
                    party, or promote or constitute illegal activity</span></li>
                <li className="c2"><span className="c4">Improperly use iDream’s support services, including without limitation submission of
                    false reports of abuse or misconduct by any party</span></li>
                <li className="c2"><span className="c4">Disable, interfere with, or circumvent any security feature of the Games or any
                    feature that restricts or enforces limitations on the use of or access to the Games or Content</span></li>
                <li className="c2"><span>Participate in any activities and/or perform any actions that, in
                </span><span>iDream’s</span><span className="c4">&nbsp;sole opinion, lead to, result in, or may result in an
                    authorized user of the Games being defrauded of Virtual Currency or Virtual Items that user has earned
                    through authorized gameplay and/or purchased in the Games</span></li>
                <li className="c2"><span className="c4">Sell iDream’s Games or any of their parts, including but not limited to Virtual
                    Currency or Virtual Items, user accounts, and/or access to user accounts to any party in exchange for real
                    currency or items and/or services of monetary value</span></li>
                <li className="c2"><span className="c4">Engage in cheating or any other fraudulent activity deemed by iDream to be in
                    conflict with the spirit of the Games</span></li>
                <li className="c2"><span className="c4">Use or take part in the use of any unauthorized third-party software designed to
                    modify or interfere with the Service and/or any iDream’s Games</span></li>
                <li className="c2"><span className="c4">Share your password or any credentials you may use to access your Account with
                    anyone</span></li>
                <li className="c2"><span className="c4">Purchase, sell, rent, or give away your Account, or otherwise make available your
                    Account to any third party; create an Account using a false identity or information, or on behalf of someone
                    other than yourself</span></li>
                <li className="c2"><span className="c4">Use your Account for commercial purposes, including but not limited to advertising,
                    or solicitation, or transmission of any commercial advertisements (junk or spam emails, chain letters,
                    etc.)</span></li>
            </ul>
            <h2 className="c1" id="h.v5d1yzpwrh1p"><span className="c0">Minors</span></h2>
            <p className="c5"><span className="c4">You must be at least 16 years old to access and/or use our website or Games. If you are a
                minor over the age of 16, we recommend seeking consent from a parent or legal guardian before accessing and/or
                using our website or Games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.h6xwka9vvhoi"><span className="c0">Termination</span></h2>
            <p className="c5"><span className="c4">We reserve the right to take actions, which may include terminating your account and
                prohibiting you from using the Service in whole or in part (including but not limited to by deleting your
                account) at any time, if we reasonably believe that you have violated or breached any of these terms. In the
                event that we suspend or terminate your account, you will have no further access to your account and/or any
                Content associated with it. As stated above, iDream and third-party e-commerce payment providers will not issue
                any refund to you for Virtual Currency or Virtual Items acquired, developed during, or purchased in the
                Games.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.n3tt3vq2yzma"><span className="c0">Applicable Law</span></h2>
            <p className="c5"><span className="c4">These Terms of Use and all disputes relating to them shall be governed by and construed
                in accordance with the laws of Republic of Ireland.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.wxnwmu604d83"><span className="c0">Severability</span></h2>
            <p className="c5"><span className="c4">You and iDream agree that if any part of these terms is or becomes, in whole or in part,
                invalid or unenforceable under any applicable local laws or court, it is to be deemed severed from these terms
                to the extent of its invalidity or unenforceability, and the rest of the terms shall remain in full force and
                effect.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <h2 className="c1" id="h.y17j1u1rl5dp"><span className="c0">Supplemental Policies</span></h2>
            <p className="c5"><span className="c4">Additional policies related to specific services (including but not limited to forums,
                contests, or loyalty programs) can be issued by iDream. Your right to use such services is subject to the
                pertinent policies and these Terms of Use.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">YOU AGREE THAT YOUR USE OF THE INFORMATION, CONTENT, OR SERVICES ACKNOWLEDGES THAT YOU
                HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.</span></p>
            <p className="c3"><span className="c4"></span></p>
            <p className="c5"><span className="c4">If you have any questions or comments, please contact us at
                support@idreaminteractive.com. Please also see our Privacy Policy.</span></p>
        </>
    );
}

