import React from "react"
import Layout from "../../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { HeaderInfo } from "../../components/layout/HeaderInfo";
import { Container } from "../../components/layout/Container";
import { Terms } from "../../components/sections/policy/Terms";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    return (
        <Layout
            pageTitle={"Terms of Use - iDream Interactive"}
            keywords={"idream terms of use, idream interactive terms of use"}
            description={"Learn more about our terms of use."}
            url={"policy/terms"}>
            <Navigation />
            <ParallaxHeader size={HeaderSize.SMALL} straightBottom={true}>
                <HeaderInfo
                    title={"Terms of Use"}
                    className="centered-header contact-header"
                />
            </ParallaxHeader>
            <Container className="policy">
                <Terms />
            </Container>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`